@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

a {
  color: #25b1ff;
}

.App {
  width: 100%;
  font-family: "Poppins", sans-serif;
  background: white;
}

.Container {
  max-width: 1260px;
  margin: 0 auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.Divider-vertical {
  width: 1px;
  height: 32px;
  background: #e5e5e5;
  display: block;
  margin: 0 0.75em;
}

.App-header {
  top: 0;
  position: fixed;
  width: 100%;
  padding: 1em 0;
  border-bottom: 1px solid #e5e5e5;
  background: white;
  z-index: 2;
}

.App-header .Container {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-header--Label {
  margin-top: 2px;
}

.App-logo {
  height: 2.5rem;
}

.App-content {
  margin-top: 86px;
  margin-bottom: 40px;
}

.Overview {
  border-left: 1px solid #e5e5e5;
}

.Overview-header {
  padding: 1em;
  font-weight: bold;
  background: #efefef;
  font-size: 14px;
}

.Overview-body {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

@media only screen and (max-width: 600px) {
  .Overview-body {
    grid-template-columns: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Overview-body {
    grid-template-columns: 50% 50%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Overview-body {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

.Environment {
  padding: 1em;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Environment-body {
  width: 100%;
}

.Environment-name {
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.Environment-info {
  margin: 0.4em 0 0 0;
  font-size: 13px;
}

.Environment-status {
  height: 20px;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 100%;
  background: lightgray;
}

.Environment-status.online {
  background: #0bb86e;
}

.Environment-status.offline {
  background: #ff4d4f;
}

.Overall {
  text-align: center;
  padding: 3em 0;
}

.Overall h1 {
  padding: 0;
  margin: 0;
}

.Overall h1 {
  margin-top: 1em;
}

.Overall-icon {
  margin: 0 auto;
  height: 240px;
  width: 240px;
  border-radius: 100%;
  background: lightgray;
  color: white;
  position: relative;
}

.Overall-icon > div {
  transform: scale(0.8);
}

.Overall-icon:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: lightgrey;
  border-radius: 100%;
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  60% {
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.Overall-icon.good {
  background: #0bb86e;
  -webkit-box-shadow: 0 6px 30px 0 rgba(11, 184, 110, 1);
  -moz-box-shadow: 0 6px 30px 0 rgba(11, 184, 110, 1);
  box-shadow: 0 6px 30px 0 rgba(11, 184, 110, 1);
}

.Overall-icon.good:before {
  background: #0bb86e;
}

.Overall-icon.warning {
  background: #f6bb56;
  -webkit-box-shadow: 0 6px 30px 0 rgba(246, 187, 86, 1);
  -moz-box-shadow: 0 6px 30px 0 rgba(246, 187, 86, 1);
  box-shadow: 0 6px 30px 0 rgba(246, 187, 86, 1);
}

.Overall-icon.warning:before {
  background: #f6bb56;
}

.Overall-icon.error {
  background: #ff4d4f;
  -webkit-box-shadow: 0 6px 30px 0 rgba(255, 77, 79, 1);
  -moz-box-shadow: 0 6px 30px 0 rgba(255, 77, 79, 1);
  box-shadow: 0 6px 30px 0 rgba(255, 77, 79, 1);
}

.Overall-icon.error:before {
  background: #ff4d4f;
}

.Password {
  display: inline-block;
  margin: 0 auto;
  padding: 0.4em 0.6em;
  border: 1px solid lightgrey;
  position: relative;
  top: 2em;
  border-radius: 12px;
}
.Password input {
  top: 0;
  left: 0;
  right: 0;
  background: none;
  padding: 0;
  margin: 0;
  margin-right: 0.4em;
  border: none;
  outline: none;
}

.Password button {
  outline: none;
  border: none;
  color: white;
  background: #25b1ff;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-size: 0.875rem;
}

@media (prefers-color-scheme: dark) {
  html,
  .App,
  .App-header {
    background: black;
    color: white;
  }
  .App-header {
    border-color: #565656;
  }
  .Divider-vertical {
    background: #565656;
  }
  .App-header--Label {
    color: lightgrey;
  }
  .Overview {
    border-color: #565656;
    background: #282828;
  }
  .Overview-header {
    background: #565656;
  }
  .Environment {
    border-color: #565656;
  }
}
